var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.addArea}},[_vm._v("添加代理区域")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.getList(1)}}},[_vm._v("查询")]),_c('auto-table',{attrs:{"DataList":_vm.DataList,"option":_vm.Option,"total":_vm.Total},on:{"changePage":_vm.getList},scopedSlots:_vm._u([{key:"area",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(row.province)+" "+_vm._s(row.city)+" "+_vm._s(row.district)+" ")])]}},{key:"level",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(_vm.agentLevelMap[row.type])+" ")])]}},{key:"ratio",fn:function(ref){
var row = ref.row;
return [(row.map.divide)?_c('div',[_vm._v(_vm._s(row.map.divide.length)+"项分成")]):_c('div',[_vm._v("未配置分成")])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [(row.status === 1)?_c('el-tag',{attrs:{"size":"small","type":"success"}},[_vm._v("正常")]):_vm._e(),(row.status === 2)?_c('el-tag',{attrs:{"size":"small","type":"danger"}},[_vm._v("冻结")]):_vm._e()]}},{key:"handler",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.openUpdate(row)}}},[_vm._v("编辑")]),(row.status === 1)?_c('el-button',{attrs:{"size":"small","type":"danger"},on:{"click":function($event){return _vm.statusUpdate(row, 2)}}},[_vm._v("冻结")]):_vm._e(),(row.status === 2)?_c('el-button',{attrs:{"size":"small","type":"success"},on:{"click":function($event){return _vm.statusUpdate(row, 1)}}},[_vm._v("解冻")]):_vm._e()]}}])}),_c('agentArea',{ref:"agentArea",on:{"close":function($event){return _vm.getList(1)},"success":function($event){return _vm.getList(1)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }