<template>
  <div class="container">
    <div class="info" v-if="cooperator.id">
    <el-descriptions title="代理商信息">
      <el-descriptions-item label="代理商">{{
        cooperator.title
      }}</el-descriptions-item>
      <el-descriptions-item label="联系人">{{
        cooperator.name
      }}</el-descriptions-item>
      <el-descriptions-item label="电话">{{
        cooperator.phone
      }}</el-descriptions-item>
       <el-descriptions-item label="账户余额">￥<span style="font-weight:600;">{{
        cooperator.account 
      }}</span></el-descriptions-item>
      <el-descriptions-item label="类型">
        <el-tag v-if="cooperator.type == 'agent'" size="small"
          >代理商</el-tag
        >
        <el-tag v-if="cooperator.type == 'sub_agent'" size="small">二级代理商</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="地址">{{
        cooperator.province
      }}-{{
        cooperator.city
      }}-{{
        cooperator.district
      }}-{{
        cooperator.address
      }}</el-descriptions-item>
      <el-descriptions-item label="介绍">
        {{ cooperator.introduction }}
      </el-descriptions-item>
      <el-descriptions-item label="介绍图片">
        <div class="flex" v-if="cooperator.introduction_img">
          <el-image
            v-for="(pic, i) in cooperator.introduction_img.split(',')"
            :key="i"
            style="width: 100px; height: 100px"
            :src="pic"
          ></el-image>
        </div>
      </el-descriptions-item>
    </el-descriptions>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick" v-if="show">
      <el-tab-pane label="收益管理" name="income">
        <income ref="income" :cooperator_id="id"></income>
      </el-tab-pane>
        <el-tab-pane label="代理区域管理" name="areaList">
        <areaList ref="areaList" :coop="cooperator"></areaList>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import areaList from './components/details/areaList.vue';
import income from "./components/details/income.vue";

export default {
  components: {
    income,
    areaList
  },
  data() {
    return {
      id: "",
      cooperator: {},
      activeName: "income",
      show: false,
      cashList:[]
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getDetails();
  },

  methods: {
    handleClick(tab) {
      this.$refs[tab.name].load();
    },
    
    getDetails() {
      this.show = true;
      this.$axios({
        url: "/user/cooperator/findById",
        params: {
          id: this.id,
        },
      }).then((res) => {
        this.cooperator = res.data.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.info{
    margin-bottom: 30px;
}
</style>