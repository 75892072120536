<template>
  <div class="">
    <el-button type="primary" @click="addArea">添加代理区域</el-button>
    <el-button type="primary" @click="getList(1)">查询</el-button>

    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #area="{ row }">
        <div>
          {{ row.province }}
          {{ row.city }}
          {{ row.district }}
        </div>
      </template>
      <template #level="{ row }">
        <div>
          {{ agentLevelMap[row.type] }}
        </div>
      </template>
      <template #ratio="{ row }">
        <div v-if="row.map.divide">{{row.map.divide.length}}项分成</div>
         <div v-else>未配置分成</div>
      </template>
      <template #status="{ row }">
        <el-tag size="small" type="success" v-if="row.status === 1"
          >正常</el-tag
        >
        <el-tag size="small" type="danger" v-if="row.status === 2">冻结</el-tag>
      </template>
      <template #handler="{ row }">
        <el-button size="small" type="primary" @click="openUpdate(row)"
          >编辑</el-button
        >
        <el-button size="small" v-if="row.status === 1" type="danger" @click="statusUpdate(row, 2)"
          >冻结</el-button
        >
        <el-button size="small" v-if="row.status === 2"  type="success" @click="statusUpdate(row, 1)"
          >解冻</el-button
        >
      </template>
    </auto-table>
    <agentArea @close="getList(1)" ref="agentArea" @success="getList(1)"></agentArea>
  </div>
</template>

<script>
import agentArea from "../agentArea.vue";

export default {
  components: {
    agentArea,
  },
  props: {
    coop: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      DataList: [],
      Option: [
        { name: "代理区域", value: "area", type: "custom" },
        { name: "代理级别", value: "level", type: "custom" },
        { name: "代理分成", value: "ratio", type: "custom" },
        { name: "代理开始", value: "agent_start_time" },
        { name: "代理结束", value: "agent_end_time" },
        { name: "状态", value: "status", type: "custom" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      agent_id: "",
      agentLevelMap: {
        province: "省代理",
        city: "市代理",
        district: "县区代理",
      },
    };
  },

  mounted() {
    this.agent_id = this.$route.query.id;
  },

  methods: {
    openUpdate(row) {
      this.$refs.agentArea.open(this.coop, { ...row });
    },
    addArea() {
      this.$refs.agentArea.open(this.coop);
    },
    load() {
      if (this.DataList.length == 0) {
        this.getList(1);
      }
    },
    statusUpdate(row, status) {
      const tips = {
        1: "确定要解冻代理区域吗，解冻后会开始分成代理区域的订单",
        2: "确定要冻结代理区域吗，冻结后会停止分成代理区域的订单",
      };
      if(status === 1){
            console.log(new Date(row.agent_end_time).getTime());
            if(new Date().getTime() > new Date(row.agent_end_time).getTime()){
                this.$message("代理已过期，无法解冻")
                return
            }
      }
      this.$confirm(tips[status]).then((res) => {
        this.$axios({
          url: "/user/agentArea/update",
          data: {
           id:row.id,
           status
          },
          method:'post'
        }).then((res) => {
          this.getList(1)
        });
      });
    },
    //分成记录
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/agentArea/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          agent_id: this.agent_id,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>