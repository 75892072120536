export const divideTypes = [
    {
        type:'course',
        name:'机构课包'
    },
    {
        type:'platform_course',
        name:'官方课包'
    },
    // {
    //     type:'need',
    //     name:'教练需求'
    // },
    {
        type:'coach_member',
        name:'教练会员'
    },
    // {
    //     type:'user_member',
    //     name:'用户会员'
    // },
    {
        type:'create_union',
        name:'公会创建费'
    }
]