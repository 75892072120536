<template>
  <div>
    <el-dialog :before-close="closeHandler" title="提示" :visible.sync="dialogVisible" width="40%">
      <el-form>
        <el-form-item label="代理级别">
          {{ curAgent.name }}({{ agentLevelMap[curAgent.agent_level] }})
        </el-form-item>
        <el-form-item label="代理区域">
          <el-cascader
            clearable
            @change="changeCity"
            v-model="cityList"
            :options="areaOptions"
            :props="cityProps"
            :placeholder="cityList.join('-') || '请选择代理区域'"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="代理时间">
          <el-date-picker
            v-model="timeList"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd hh:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="到账日期">
          <el-radio-group v-model="form.account_day">
            <el-radio
              :label="v.value"
              v-for="(v, i) in accountDayList"
              :key="i"
              >{{ v.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>


        <div v-if="temp.id">
        <h3>分成比例设置</h3>

        <el-popover placement="right" width="350" v-model="visible">
          <div class="mt-10">
            <el-radio
              v-model="divide.type"
              v-for="(v, i) in canDivideType"
              :key="i"
              :label="v.type"
              @input="divide.name = v.name"
              >{{ v.name }}</el-radio
            >
          </div>
          <div class="mt-10">
            分成比例<el-input
              type="Number"
              :max="100"
              :min="0"
              :maxlength="2"
              style="margin: 0 5px; width: 100px !important"
              v-model="divide.divide_ratio"
              size="small"
              placeholder="请输入内容"
            ></el-input
            >%
          </div>
          <div class="mt-10">
            运营成本<el-input
              type="Number"
              :max="100"
              :min="0"
              :maxlength="2"
              style="margin: 0 5px; width: 100px !important"
              v-model="divide.cost_ratio"
              size="small"
              placeholder="请输入内容"
            ></el-input
            >%
          </div>

          <div class="mt-10">
            <el-button size="mini" type="text" @click="visible = false"
              >取消</el-button
            >
            <el-button type="primary" v-if="canDivideType.length > 0" size="mini" @click="addAreaDivide"
              >确定</el-button
            >
          </div>
          <el-button type="primary" size="small" slot="reference"
            >添加分成项目</el-button
          >
        </el-popover>

        <auto-table :DataList="DataList" :option="Option" :showPage="false">
          <template #divide_ratio="{ row }">
            <div>{{ (row.divide_ratio * 100).toFixed(2) }} %</div>
          </template>
          <template #cost_ratio="{ row }">
            <div>{{ (row.cost_ratio * 100).toFixed(2) }} %</div>
          </template>
          <template #handler="{ row }">
            <el-button type="text" @click="openUpdate(row)">编辑</el-button>
            <el-button
              type="text"
              style="margin-left: 10px; color: #ff3d3d"
              @click="delAreaDivide(row)"
              >删除</el-button
            >
          </template>
        </auto-table>
        </div>
      </el-form>
      

      <el-dialog
        width="30%"
        :visible.sync="updateShow"
        append-to-body
      >
        <el-form>
          <el-form-item label="代理级别">
            {{ curDivide.name }}
          </el-form-item>
          <el-form-item label="代理级别">
            分成比例<el-input
              type="Number"
              :max="100"
              :min="0"
              :maxlength="2"
              v-model="curDivide.divide_ratio"
              size="small"
              placeholder="请输入内容"
            ></el-input
            >%
          </el-form-item>
          <el-form-item label="代理级别">
            运营成本<el-input
              type="Number"
              :max="100"
              :min="0"
              :maxlength="2"
              v-model="curDivide.cost_ratio"
              size="small"
              placeholder="请输入内容"
            ></el-input
            >%
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
        <el-button @click="updateShow = false">取 消</el-button>
        <el-button type="primary" @click="updateAreaDivide">确 定</el-button>
      </span>
      </el-dialog>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { ObjectChangedValue } from "@/js/utils.js";
import { divideTypes } from "./areaDivideType";
export default {
  data() {
    return {
      cityList: [],
      areaOptions: [],
      cityProps: {
        value: "district",
        label: "district",
        children: "childrens",
      },
      dialogVisible: false,
      form: {},
      curAgent: {},
      temp: {},
      agentLevelMap: {
        province: "省代理",
        city: "市代理",
        district: "县区代理",
      },
      timeList: [],
      adminInfo: JSON.parse(localStorage.getItem("managerInfo")),
      accountDayList: [
        {
          value: "n+3",
          label: "三天后",
        },
        {
          value: "n+7",
          label: "七天后",
        },
        {
          value: "m-1",
          label: "每月1号",
        },
      ],
      //分成比例
      divideTypes: divideTypes,
      visible: true,
      divide: { divide_ratio: 0, cost_ratio: 0, type: "" },
      DataList: [],
      Option: [
        { name: "分成", value: "name" },
        { name: "分成比例", value: "divide_ratio", type: "custom" },
        { name: "运营成本", value: "cost_ratio", type: "custom" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,

      updateShow: false,
      curDivide: {},
    };
  },

  mounted() {},

  computed: {
    canDivideType() {
      let types = this.DataList.map((t) => t.type);
      let res = this.divideTypes.filter((v) => !types.includes(v.type));
      if(res.length > 0){
         this.divide.type = res[0].type;
         this.divide.name = res[0].name;
      }
     
      return res;
    },
  },

  methods: {
    closeHandler(done){

        this.$emit("close",{})
        done()
    },
    submitForm() {
      if (this.cityList.length === 0) {
        this.$message("请选择代理区域");
        return;
      }
      if (this.timeList.length === 0) {
        this.$message("请选择代理时间");
        return;
      }

      let url = "/user/agentArea/insert";
      this.form.agent_id = this.curAgent.id;
      this.form.agent_start_time = this.timeList[0];
      this.form.agent_end_time = this.timeList[1];
      this.form.type = this.curAgent.agent_level;
      this.form.province = this.cityList[0];
      this.form.city = this.cityList[1];
      this.form.district = this.cityList[2];
      this.form.handler_id = this.adminInfo.id;
      this.form.handler_name = this.adminInfo.name;
      if (this.form.id) {
        const data = ObjectChangedValue(this.temp, this.form);
        if (Object.keys(data).length == 0) {
          this.$message("没有修改数据，无需更新");
          this.dialogVisible = false;
          return;
        }
        url = "/user/agentArea/update";
      }

      this.$axios({
        url,
        data: this.form,
        method: "post",
      }).then((res) => {
        this.form = {};
        this.dialogVisible = false;
        // this.$emit("success", res);
      });
    },
    open(row, area) {
      this.temp = {};
      this.form = {}
      this.cityList = [];
      this.timeList = [];
      this.dialogVisible = true;
      let cityoptions = JSON.parse(localStorage.getItem("addrList"));
      this.curAgent = row;
      this.divide = { divide_ratio: 0, cost_ratio: 0, type: "" }
      this.DataList = []
      let { agent_level } = row;
      switch (agent_level) {
        case "province":
          this.areaOptions = cityoptions.map((item) => {
            item.childrens = null;
            return item;
          });
          break;
        case "city":
          this.areaOptions = cityoptions.map((item) => {
            if (item.childrens instanceof Array)
              item.childrens.map((v) => {
                v.childrens = null;
              });
            return item;
          });
          break;
        case "district":
          this.areaOptions = cityoptions;
          break;
        default:
          break;
      }
      if (area) {
        this.temp = Object.assign({}, area);
        this.form = area;
        this.cityList.push(area.province);
        this.cityList.push(area.city);
        this.cityList.push(area.district);
        this.timeList.push(area.agent_start_time);
        this.timeList.push(area.agent_end_time);
        this.getAreaDivide();
      }
    },
  //获取分成比例
    getAreaDivide() {
      this.$axios({
        url: "user/agentAreaDivide/query",
        params: {
          area_id: this.temp.id,
        },
      }).then((res) => {
        this.DataList = res.data.data;
      });
    },
//打开分成更新
    openUpdate(row){
      this.curDivide = row
      row.divide_ratio =  (row.divide_ratio * 100).toFixed(0)
       row.cost_ratio =  (row.cost_ratio * 100).toFixed(0)
      this.updateShow = true
    },
//添加分成
    addAreaDivide() {
      if (!this.divide.type) {
        return;
      }
       if(this.divide.divide_ratio > 100){
        this.$message("比例不能大于100")
          return
      }
      if(this.divide.cost_ratio > 100){
        this.$message("比例不能大于100")
          return
      }
      this.$axios({
        url: "user/agentAreaDivide/insert",
        data: {
          coop_id: this.curAgent.id,
          area_id: this.form.id,
          divide_ratio: this.divide.divide_ratio / 100,
          cost_ratio: this.divide.cost_ratio / 100,
          name: this.divide.name,
          type: this.divide.type,
        },
        method: "post",
      }).then((res) => {
        this.visible = false;
        this.getAreaDivide();
      });
    },
    //更新
    updateAreaDivide() {
      if(this.curDivide.divide_ratio > 100){
        this.$message("比例不能大于100")
          return
      }
      if(this.curDivide.cost_ratio > 100){
        this.$message("比例不能大于100")
          return
      }
      this.$axios({
        url: "user/agentAreaDivide/update",
        data: {
          id: this.curDivide.id,
          divide_ratio: this.curDivide.divide_ratio / 100,
          cost_ratio: this.curDivide.cost_ratio / 100,
        },
        method: "post",
      }).then((res) => {
        this.updateShow = false;
        this.getAreaDivide();
      });
    },
    //删除
    delAreaDivide(row) {
      this.$confirm("确定删除分成比例吗").then(() => {
        this.$axios({
          url: "user/agentAreaDivide/delete",
          params: {
            id: row.id,
          },
        }).then((res) => {
          this.getAreaDivide();
        });
      });
    },

    changeCity() {},
  },
};
</script>

<style lang="less" scoped>
.mt-10 {
  margin-top: 10px;
}
h3 {
  margin-bottom: 10px;
}
</style>